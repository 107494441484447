import { ChevronsUp, Copy } from "react-feather";
import { useConfig } from "../store/config";
import { useEffect, useState } from "react";
import SupportUsModal from "./SupportUsModal";
import BeraImg from "../assets/bera.svg"
import { bArtio } from "../wagmi";
const FaucetInfo = () => {
    const config = useConfig();
    const [animateCopy, setAnimateCopy] = useState(false);
    const [isShowSupportUs, setIsShowSupportUs] = useState(false);
    const [showImportant, setShowImportant] = useState<boolean>();

    useEffect(() => {
        if (isShowSupportUs === true) {
            localStorage.setItem("supportUsShowed", "true");
        }
    }, [isShowSupportUs]);
    useEffect(() => {
        if (animateCopy) {
            const timeout = setTimeout(() => setAnimateCopy(false), 1000);
            return () => clearTimeout(timeout);
        }
    }, [animateCopy]);

    const copy = () => {
        if (
            config?.address &&
            typeof navigator !== "undefined" &&
            navigator.clipboard
        ) {
            navigator.clipboard.writeText(config.address);
            setAnimateCopy(true);
        }
    };

    useEffect(() => {
        if (
            typeof localStorage !== "undefined" &&
            localStorage.getItem("supportUsShowed") === "true"
        ) {
            return;
        }

        const handleVisibilityChange = () => {
            if (
                document.visibilityState === "visible" &&
                showImportant === undefined
            ) {
                setShowImportant(true);
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, [showImportant]);

    return (
        <div className="select-none flex flex-col items-center justify-center">
            <SupportUsModal
                isShow={isShowSupportUs}
                onClose={() => setIsShowSupportUs(false)}
            />
            <div className="text-6xl md:text-9xl">
                <img
                    className="w-[1em] md:w-[2em] h-[1em] md:h-[2em]"
                    src={BeraImg}
                />
            </div>
            <div className="text-xs text-nowrap text-slate-500 pt-1 flex flex-col items-center">
                <div className="flex flex-col items-center gap-1">
                    <div>Give it back when you can</div>
                    {config ? (
                        <div
                            className={`text-xs text-nowrap text-slate-600 h-7 flex gap-1 items-center ${
                                animateCopy ? " animate-ping" : ""
                            }`}
                        >
                            <a
                                href={`${bArtio?.blockExplorers?.default.url}/address/${config.address}`}
                                target="_blank"
                            >
                                {config.address.slice(0, 4)}...
                                {config.address.slice(-4)}
                            </a>
                            <button
                                type="button"
                                onClick={copy}
                                className="cursor-pointer p-2 rounded-md hover:bg-slate-200"
                            >
                                <Copy className="h-[1.2em] w-[1.2em]" />
                            </button>
                            <div className="opacity-50">or</div>
                            <div
                                className="relative"
                                onClick={() => setShowImportant(false)}
                            >
                                <div
                                    onClick={() => {
                                        setIsShowSupportUs(true);
                                        setShowImportant(false);
                                    }}
                                    className="relative text-xs text-blue-500 underline underline-offset-4 p-2 cursor-pointer rounded-md hover:bg-slate-200 z-30"
                                >
                                    🙏 Just support us
                                    {showImportant && (
                                        <div className="absolute left-0 top-0 z-30 translate-y-[120%] text-white items-center justify-center flex flex-col">
                                            <div className="animate-bounce flex flex-col gap-1 items-center">
                                                <ChevronsUp className="w-4 h-auto" />
                                                It's important to us!
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-7 animate-pulse bg-slate-200 rounded-md"></div>
                    )}
                </div>

                {showImportant && (
                    <div
                        onClick={() => setShowImportant(false)}
                        className="absolute left-0 top-0 w-screen h-screen z-20 opacity-70 bg-black"
                    ></div>
                )}
            </div>
        </div>
    );
};

export default FaucetInfo;
