import { useEffect } from "react";
import SubscribeSocials from "./SubscribeSocials";
import { useSubscribedSocialsStore } from "../store/subscribedSocials";

const SupportUsModal = ({
    isShow,
    onClose,
    required = false,
}: {
    isShow: boolean;
    onClose: () => void;
    required?: boolean;
}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClose = (e: any) => {
        if (e.target.id === "support-us-modal") {
            onClose();
        }
    };

    useEffect(() => {
        if(typeof window === "undefined") return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleKeyPress = (event: any) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const subscribed = useSubscribedSocialsStore(state => state.subscribed);

    if (!isShow) return null;

    return (
        <div
            onClick={handleClose}
            id="support-us-modal"
            className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:items-center justify-center z-40 p-0"
        >
            <div className="bg-white p-4 rounded-t-lg md:rounded-b-lg max-h-[90%] overflow-y-auto max-w-md w-full">
                <div className="flex flex-col gap-2">
                    <div className="text-2xl">{required ? "Subscribe required" : "Support us"}</div>
                    {required ? (
                        <div className="text-xs text-wrap">
                            First, subscribe for our socials
                        </div>
                    ) : (
                        <div className="text-xs text-wrap">
                            Join our socials, that way you'll support us and stay up
                            to date!
                        </div>
                    )}
                    <SubscribeSocials />
                    {required === true && <button disabled={!subscribed} onClick={onClose} className="not(disabled):hover:opacity-50 disabled:bg-slate-400 bg-blue-500 text-white p-2 rounded-md cursor-pointer mt-1">
                        Continue
                    </button>}
                </div>
            </div>
        </div>
    );
};

export default SupportUsModal;
