import { ReactNode, useEffect, useMemo, useState } from "react";
import { Activity, Twitter } from "react-feather";
import { useQueueCount } from "../store/queueCount";
import { useBalance } from "wagmi";
import { useConfig } from "../store/config";
import { formatEther } from "viem";


const Count = ({ count }: { count: number | string | ReactNode | null }) => {
    const [prev, setPrev] = useState(count);
    const [anim, setAnim] = useState(false);

    useEffect(() => {
        setAnim(true);

        const timeout = setTimeout(() => {
            setPrev(count);
            setAnim(false)
        }, 1000)


        return () => clearTimeout(timeout)
        
    }, [count, setPrev])

    const activityColor = useMemo(() => {
        if (typeof count !== "number") {
            return undefined;
        }
        
        if (!count) return "text-slate-400";
        
        if (count < 10) {
            return "text-green-600";
        } else if (count < 100) {
            return "text-yellow-600";
        } else {
            return "text-red-600";
        }
    }, [count])

    if (count === null) {
        return <div className="w-10 h-[1em] bg-slate-300 rounded animate-pulse"></div>
    }
    
    return <div className={`flex flex-row w-full gap-1 h-[1em] text-xs ${prev === 0 ? 'text-slate-300 transition-colors' : ''}`}>
        {activityColor && <Activity className={`w-[1em] shrink-0 h-auto ${activityColor}`} />}

        <div className="flex flex-col relative justify-center items-start overflow-hidden w-full">
            <div className={`select-none  absolute ${!anim ? "-translate-y-full" : "transition-transform duration-1000"}`}>{count}</div>
            <div className={`absolute ${anim ? "transition-transform duration-1000 translate-y-full" : ""}`}>{prev}</div>
        </div>
    </div>;
}
const HeadBranding = () => {
    const count = useQueueCount()
    const config = useConfig()

    const { data: balance } = useBalance({
        address: config?.address as `0x${string}`,
    });
    
    const formattedBalance = useMemo(() => {
        if (balance?.value === undefined) return null;

        return <div className="flex gap-1 items-center">
            {parseFloat(formatEther(balance.value)).toFixed(2)}
            <span className="text-slate-400 select-none">$BERA</span>
        </div>

    }, [balance])
    return (
        <div>
            <h1 className="text-3xl font-bold flex gap-2 items-center">
                <a
                    className="twitter-share-button p-2 md:p-1 hover:bg-slate-900/10 rounded-md translate-y-0.5"
                    target="_blank"
                    href="https://twitter.com/intent/tweet?text=%F0%9F%90%BB%E2%80%8D%E2%9D%84%EF%B8%8F%20Just%20successfully%20received%20bartio%20%24BERA%20on%20faucet%20from%20%40kodiakfi%20on%20the%20%40berachain%0ABe%20sure%20to%20make%20it%20with%20me%20while%20it%27s%20still%20wet%21%20%0A%0Afaucet.kodiak.finance"
                    data-size="large"
                >
                    <Twitter className="fill-blue-400 text-blue-400" />
                </a>
                <span className="text-amber-700">bArtio</span>
                <span className="text-amber-800">Den</span>
            </h1>

            <div className="flex flex-row gap-2 items-center">
                <span className="text-sm text-slate-600 flex flex-row gap-1 items-center h-full w-36">
                    <div className="opacity-50 text-xs select-none ">QUEUE</div>
                    <Count count={count} />
                </span>
                <span className="text-sm text-slate-600 flex flex-row gap-1 items-center h-full w-full">
                    <div className="opacity-50 text-xs select-none">
                        RESERVED
                    </div>
                    <Count count={formattedBalance} />
                </span>
            </div>
        </div>
    );
}
 
export default HeadBranding;