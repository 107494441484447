import React from "react";
import Dots from "./Dots";
import { Info } from "react-feather";



export const LongClaimingView: React.FC = () => {

    return (
        <div className="flex flex-row gap-2 text-orange-500 items-center w-full">
            <Info className="w-[1.5em] h-[1.5em]" />
            <div className="text-xs">Don't worry, we're getting closer<Dots /></div>
        </div>
    );
}

export default LongClaimingView;