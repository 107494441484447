import { useEffect, useState } from "react";
import { Check, Twitter } from "react-feather";
import discordLogo from "../assets/discord.png";
import { useSubscribedSocialsStore } from "../store/subscribedSocials";

const Twitters = [
    {
        username: "KodiakFi",
        avatar: "https://pbs.twimg.com/profile_images/1669385759923785729/Qh5SqiwT_400x400.jpg",
        discord: "kodiak",
    },
    {
        username: "0xbeartic",
        avatar: "https://pbs.twimg.com/profile_images/1841175014139179014/NewOf3C1_400x400.jpg",
    },
    {
        username: "BeraRodman",
        avatar: "https://pbs.twimg.com/profile_images/1834357091198541824/R3J0FDP6_400x400.jpg",
    },
    {
        username: "berastotle",
        avatar: "https://pbs.twimg.com/profile_images/1848727371156688896/SJ6-aRJF_400x400.jpg",
    },
    {
        username: "bearithm",
        avatar: "https://pbs.twimg.com/profile_images/1786443918864707584/uPrBVcjx_400x400.jpg",
    },
    {
        username: "zero_Jpegg",
        avatar: "https://pbs.twimg.com/profile_images/1842498968938487808/ogh0S_6Y_400x400.jpg",
    }
];

const TwitterSubscribeLink = ({
    username,
    onSubscribed,
}: {
    username: string;
    onSubscribed?: () => void;
}) => {
    const [clicked, setClicked] = useState(false);
    return (
        <a
            onClick={() => {
                if (!clicked) {
                    onSubscribed?.();
                }
                setClicked(true);
            }}
            href={`https://twitter.com/${username}`}
            target="_blank"
            className={`flex items-center gap-2 ${
                clicked
                    ? "bg-slate-100 text-black text-opacity-50"
                    : "bg-blue-500 text-white"
            } rounded-md px-3 md:px-2 py-2 md:py-1 text-base`}
        >
            {!clicked ? (
                <>
                    Follow <Twitter className="fill-white w-4 h-4" />
                </>
            ) : (
                <>
                    Thanks <Check className="w-5 h-5" />
                </>
            )}
        </a>
    );
};
const DiscordSubscribeLink = ({ username }: { username: string }) => {
    return (
        <a
            href={`https://discord.gg/${username}`}
            target="_blank"
            className="flex items-center gap-2 bg-purple-950 rounded-md text-white px-3 py-2 md:px-2 md:py-1 text-base"
        >
            Join <img className="w-4 h-auto" src={discordLogo} />
        </a>
    );
};

type SubscribeSocialsProps = {
    includeDiscord?: boolean;
    gapClassName?: string;
};

const SubscribeSocials = ({
    includeDiscord = true,
    gapClassName = "gap-2 md:gap-1",
}: SubscribeSocialsProps) => {
    const [subscribed, setSubscribed] = useState(0);
    const onSubscribed = () => {
        setSubscribed(subscribed + 1);
    };
    const setSubscribedStore = useSubscribedSocialsStore(
        (store) => store.setSubscribed
    );
    useEffect(() => {
        console.log(subscribed, Twitters.length);
        if (subscribed === Twitters.length) {
            setSubscribedStore(true);
        }
    }, [subscribed, setSubscribedStore]);
    return (
        <div className={`flex flex-col ${gapClassName ?? ""}`}>
            {Twitters.map((twitter) => (
                <div
                    key={twitter.username}
                    className="flex gap-2 items-center text-base justify-between"
                >
                    <div className="flex items-center gap-2 ">
                        <img
                            src={twitter.avatar}
                            className="w-8 h-8 rounded-full"
                        />
                        <a
                            href={`https://twitter.com/${twitter.username}`}
                            target="_blank"
                            className="text-blue-500"
                        >
                            @{twitter.username}
                        </a>
                    </div>
                    <div className="flex flex-row gap-1">
                        <TwitterSubscribeLink
                            onSubscribed={onSubscribed}
                            username={twitter.username}
                        ></TwitterSubscribeLink>
                        {twitter.discord && includeDiscord && (
                            <DiscordSubscribeLink username={twitter.discord} />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SubscribeSocials;
