import { useCallback, useState } from "react";

const useGenerateProof = (seed: string | null) => {
    const [proof, setProof] = useState<number | null>(null);

    const run = useCallback(async (overrideSeed?: string) => {
        const s = overrideSeed || seed;
        if(s === null) return;
        console.log(`generate proof for ${s}...`)
        
        const encoder = new TextEncoder();
        let i = 0;
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const data = encoder.encode(s + (++i));
            const hashBuffer = await crypto.subtle.digest("SHA-256", data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));

            if (hashArray[0] === 0 && hashArray[1] === 0 && hashArray[2] <= 64) {
                setProof(i);
                return i;
            }
        }
    }, [seed]);

    return {
        seed,
        generateProof: run,
        proof
    }
};


export default useGenerateProof;
