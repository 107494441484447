import React from "react";
import { AlertTriangle } from "react-feather";


const AlertVariants = {
    danger: {
        className: "bg-red-400 outline-red-500",
        icon: <AlertTriangle className="h-full w-full" />
    }
}
export type AlertProps = {
    children: React.ReactNode | "string" | undefined | null | number,
    variant?: keyof typeof AlertVariants
}

export const Alert: React.FC<AlertProps> = ({ children, variant = "danger" }) => {
    return <div className={`outline outline-1 rounded-md p-2 text-white flex flex-row items-center gap-2 ${AlertVariants[variant].className}`}>
        <div className="grow-0 shrink-0 h-[1.1em] w-[1.1em] flex items-center justify-center">{AlertVariants[variant].icon}</div>
        <div className="grow text-sm">{children}</div>
    </div>;
}
 
export default Alert;