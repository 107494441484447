import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { WagmiProvider } from "wagmi";
import { wagmiConfig } from "../wagmi";
// import { Analytics } from '@vercel/analytics/react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            refetchInterval: 2000,
        },
    },
});

const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <WagmiProvider config={wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                    {children}
                </QueryClientProvider>
            </WagmiProvider>
            {/* <Analytics /> */}
        </>
    );
}

export default Layout;
