import Alert from "../../components/Alert";

const InsufficientBalance = () => {
    return <div className="text-red-500 flex gap-2 text-4xl">
        <Alert>
            Oops... Looks like the tap's dried up. Don't worry, we'll pour more honey soon.
        </Alert>
    </div>;
}
 
export default InsufficientBalance;