import { useState, useEffect } from 'react';

function useCurrentTime(updateInterval: number = 2000): number {
    const [currentTime, setCurrentTime] = useState<number>(Date.now());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(Date.now());
        }, updateInterval);

        return () => clearInterval(intervalId);
    }, [updateInterval]);

    return currentTime;
}

export default useCurrentTime;