import { useEffect } from "react";
import { create } from "zustand"
import io from "socket.io-client"
import getApiUrl from "../helpers/getApiUrl";
import axios from "../helpers/axios";

interface GueueCountStore {
    count: number | null,
    update: (count: number) => void,
}

export const useQueueCountStore = create<GueueCountStore>(set => ({
    count: null,
    update: (count: number) => set({ count }),
}))

export const useQueueCount = () => {
    const { count, update } = useQueueCountStore();

    useEffect(() => {
        axios.get(getApiUrl('/queue'))
            .then(res => update(res.data.count))
            .catch(console.error);

        const url = getApiUrl().replace("https", "wss").replace("http", "ws");
        const socket = io(url, {
            transports: ["websocket", "polling"],
        
        });
        socket.on("queue", update);

        return () => {
            socket.off("queue", update);
            socket.disconnect();
        }
    }, [update])

    return count;
}