import { create } from "zustand";
import { useEffect } from "react";
import getApiUrl from "../helpers/getApiUrl";
import axios from "../helpers/axios";
export type Config = { address: string, amount: string, timeout: number, graylisted: boolean, proofRequest: string | null }
export type ConfigStore = {
    config: Config | null | undefined,
    init: () => Promise<void>
    setLoading: () => void
}

export const useConfigStore = create<ConfigStore>(set => ({
    config: undefined,
    init: async () => {
        console.log(`fetching config...`)
        const response = await axios.get(getApiUrl('/faucet'));
        set({ config: response.data })
    },

    setLoading: () => set({ config: null }),
}))

export type UseConfigParams = {
    fetch?: boolean
}

export function useConfig(): Config | null;
export function useConfig(params: UseConfigParams): Config | null;
export function useConfig(params?: UseConfigParams): Config | null {
    const { config, init, setLoading } = useConfigStore()
    
    useEffect(() => {
        if (config === undefined && params && params.fetch === true) {
            setLoading();
            init();
        }
    }, [config, init, setLoading, params])


    return config === undefined ? null : config;
}

