import { useMemo, useState } from "react";
import Kodiak from "../../components/Kodiak";
import { useConfig } from "../../store/config";
import FaucetInfo from "../../components/FaucetInfo";
import HeadBranding from "../../components/HeadBranding";
import ClaimedView from "../../components/ClaimedView";
import PoweredBy from "../../components/PoweredBy";
import "./Page.css";
import ClaimForm from "./ClaimForm";
import { useBalance } from "wagmi";
import { useQueueCountStore } from "../../store/queueCount";
import { parseEther } from "viem";
import InsufficientBalance from "./InsufficientBalance";

const localUsedLastTime = parseFloat(
    (typeof localStorage !== "undefined"
        ? localStorage.getItem("____")
        : undefined) || "0"
);

function App() {
    const [done, setDone] = useState(false);
    const config = useConfig({ fetch: true });
    const { data: balance } = useBalance({ address: config?.address as `0x${string}` })
    const count = useQueueCountStore(store => store.count);

    const localUsed = useMemo(
        () =>
            config ? Date.now() - localUsedLastTime < config.timeout : false,
        [config]
    );

    const isInsufficientBalance = useMemo(() => {
        const amount = config?.amount;
        if (!amount || !balance || count === null) return false;
        
        return balance.value < parseEther(amount) * BigInt(count);
    }, [balance, count, config])



    const isNotClaimed = !done && config?.graylisted !== true && localUsed !== true;

    return (

        <div className="flex flex-col gap-8 px-4">
            <div className="flex items-center flex-col-reverse md:flex-row gap-8 md:gap-16">
                <FaucetInfo />
                <div className="flex flex-col gap-2 w-[304px]">
                    <HeadBranding />
                    {isInsufficientBalance ? <InsufficientBalance /> : isNotClaimed ? (
                        <ClaimForm onDone={() => setDone(true)} />
                    ) : (
                        <ClaimedView
                            amount={config?.amount}
                            timeout={config?.timeout}
                        />
                    )}
                </div>
            </div>
            <PoweredBy logo={<Kodiak />} />
        </div>
    );
}

export default App;
