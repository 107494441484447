import React from 'react';
function PoweredBy<T extends React.ReactNode>({ logo }: { logo: T }) {
    return (
        <a target="_blank" href="https://twitter.com/KodiakFi" className="fixed bottom-5 w-full left-0 flex flex-col gap-2 items-center justify-center text-xs text-slate-400 hover:opacity-50">
            {logo}
            <div>Powered by Kodiak Team</div>
        </a>
    )
}
 
export default PoweredBy;