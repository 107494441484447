import { create } from "zustand";
import { persist } from "zustand/middleware";

interface SubscribedSocialsStore {
    subscribed: boolean;
    setSubscribed: (subscribed: boolean) => void;
}
export const useSubscribedSocialsStore = create(persist<SubscribedSocialsStore>((set) => ({
    subscribed: false,
    setSubscribed(subscribed) {
        set({ subscribed });
    },
}), { name: "subscribed-socials" }));
