import { useState, useEffect } from "react";

const useIsDesktop = (breakpoint = 1024) => {
    const [isDesktop, setIsDesktop] = useState(typeof window !== "undefined" ? (window.innerWidth > breakpoint) : true);

    useEffect(() => {
        if(typeof window === "undefined") return;

        const handleResize = () => {
            setIsDesktop(window.innerWidth > breakpoint);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [breakpoint]);

    return isDesktop;
};

export default useIsDesktop;
