import { useEffect, useMemo, useState } from "react";

const Dots = () => {
    const [dots, setDots] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDots((dots) => (dots + 1) % 4);
        }, 300);
        return () => clearInterval(intervalId);
    })

    return useMemo(() => Array.from({ length: dots }, () => ".").join(""), [dots]);
}
 
export default Dots;