import React from "react";
import SubscribeSocials from "./SubscribeSocials";

export type ClaimedViewProps = {
    amount?: string | null;
    timeout?: number | null;
};
const ClaimedView: React.FC<ClaimedViewProps> = ({ ...rest }) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
                <div>
                    🎉 Congratulations! You'll be getting your treat soon
                    enough, buddy
                </div>
                <ClaimLimits {...rest} />
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-md text-neutral-700 ">🙏 Our socials</div>
                <SubscribeSocials
                    gapClassName="gap-0.5 md:gap-1"
                    includeDiscord={false}
                />
            </div>
        </div>
    );
};

export type ClaimLimitsProps = ClaimedViewProps;

export const ClaimLimits: React.FC<ClaimedViewProps> = ({
    amount,
    timeout,
}: ClaimLimitsProps) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="text-blue-500 text-xs">
                You can claim {amount} BERA every {(timeout || 0) / 1000 / 60}{" "}
                minutes
            </div>
        </div>
    );
};

export default ClaimedView;