import { track } from "@vercel/analytics";
import axios from "axios";
import { useState, useMemo } from "react";
import { Info } from "react-feather";
import Alert from "../../components/Alert";
import { ClaimLimits } from "../../components/ClaimedView";
import LongClaimingView from "../../components/LongClaimingView";
import getApiUrl from "../../helpers/getApiUrl";
import useAntiBot from "../../hooks/useAntiBot";
import useCurrentTime from "../../hooks/useCurrentTime";
import useGenerateProof from "../../hooks/useGenerateProof";
import { useConfig } from "../../store/config";
import { useData } from "vike-react/useData";
import { DataType } from "./+data";
import Loader from "../../components/Loader";
import { Turnstile } from "@marsidev/react-turnstile";

export type ClaimFormProps = {
    onDone?: () => void;
};

const ClaimForm = ({ onDone }: ClaimFormProps) => {
    const config = useConfig({ fetch: false });

    const [captcha, setCaptcha] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState("");
    const [error, setError] = useState<string>();
    const [dripStartedAt, setDripStartedAt] = useState(0);
    const currentTime = useCurrentTime();

    const { generateProof } = useGenerateProof(config?.proofRequest || null);
    const allowed = useAntiBot();
    const otp = useData<DataType>();

    const drip = async () => {
        if (loading) return;

        setLoading(true);
        setError(undefined);
        const last = localStorage.getItem("____");
        try {
            if (!allowed) {
                setError("Anti bot, try again later");
                return;
            }
            setDripStartedAt(Date.now());

            const proof = await generateProof();
            const response = await axios.post(getApiUrl("/faucet"), {
                captcha,
                address,
                last: last ? Number(last) : undefined,
                proof,
                otp: otp.code,
            });
            console.log(response.data);
            onDone?.();
            track("Drip", { address });
            localStorage.setItem("____", Date.now().toString());

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            if (e.response.data.message) {
                setError(e.response.data.message);
            } else {
                setError("Something went wrong");
                console.error(e.response.data);
            }
        } finally {
            setLoading(false);
        }
    };

    const onInput: React.FormEventHandler<HTMLInputElement> = (e) => {
        setAddress((e.target as HTMLInputElement).value);
    };

    const isShowLongDrip = useMemo(() => {
        return loading && currentTime - dripStartedAt > 5000;
    }, [currentTime, dripStartedAt, loading]);

    return (
        <>
            <input
                onInput={onInput}
                value={address}
                placeholder="Enter address..."
                className="bg-slate-200 shadow outline outline-1 outline-slate-300 p-2 rounded-md"
            />
            {!import.meta.env.SSR && (
                <Turnstile
                    siteKey={import.meta.env.VITE_TURNSTILE_SITE_KEY}
                    onSuccess={(token) => setCaptcha(token)}
                    onError={() => setCaptcha("")}
                />
            )}
            {error && <Alert variant="danger">{error}</Alert>}

            <button
                disabled={!captcha}
                onClick={drip}
                className="bg-amber-700 rounded-md p-2 text-white outline outline-1 outline-amber-800 shadow disabled:bg-slate-300 disabled:outline-slate-300 flex gap-2 items-center justify-center"
            >
                <div>Drip</div>
                {loading && <Loader />}
            </button>
            {config ? (
                <div className="text-blue-500 text-xs w-full flex items-center justify-center gap-1 h-6">
                    {isShowLongDrip ? (
                        <LongClaimingView />
                    ) : (
                        <>
                            <Info className="w-[1.5em] h-[1.5em] " />
                            <ClaimLimits
                                amount={config?.amount}
                                timeout={config?.timeout}
                            />
                        </>
                    )}
                </div>
            ) : (
                <div className="w-full h-6 animate-pulse bg-slate-200 rounded-md"></div>
            )}
        </>
    );
};

export default ClaimForm;
