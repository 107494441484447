import { useState, useCallback, useMemo, useEffect } from "react";
import useIsDesktop from "./useIsDesktop";

function useAntiBot() {
    const isDesktop = useIsDesktop()

    const [history, setHistory] = useState<
        { x: number; y: number; time: number }[]
    >([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMouseMove = useCallback((e: any) => {
        setHistory((prev) => [
            ...prev.slice(-256),
            { x: e.clientX, y: e.clientY, time: Date.now() },
        ]);
    }, []);

    const speeds = useMemo(
        () =>
            history
                .map((v, i, arr) => {
                    if (i === 0) return 0;
                    const prev = arr[i - 1];

                    const dx = v.x - prev.x;
                    const dy = v.y - prev.y;
                    const dt = v.time - prev.time;

                    return Math.sqrt(dx * dx + dy * dy) / dt;
                })
                .filter((v) => v !== 0 && v !== Infinity && !isNaN(v)),
        [history]
    );

    const average = useMemo(
        () => speeds.reduce((a, b) => a + b, 0) / speeds.length,
        [speeds]
    );

    useEffect(() => {
        if(typeof window === "undefined") return;
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [handleMouseMove]);

    return useMemo(() => (average < 1.5 && speeds.length >= 30) || !isDesktop, [speeds, average, isDesktop]);
}
export default useAntiBot;
